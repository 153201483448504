import React from "react"
import styled from "@emotion/styled"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Title from "../components/misc/Title"
import ArrowStyles from "../styles/ArrowStyles"
import SideAppearAnimation from "../animations/SideAppearAnimation"
import { useOnVisibleOnce } from "../hooks/useOnVisibleOnce"

const Wrapper = styled.div({
    display: "flex",
    flexWrap: "wrap",
    backdropFilter: "blur(5px)",
    justifyContent: "space-around",
    paddingTop: "3em",
    paddingBottom: "3em",
    position: "relative",
}, ({ inverted, animate }) => ({
    backgroundColor: inverted ? "rgba(130, 130, 130, 0.85)": "rgba(240, 240, 240, 0.85)",
    flexDirection: inverted ? "row-reverse" : "row",
    animation: animate ? `${SideAppearAnimation("-100%", inverted ? "right" : "left")} 1.5s ease` : "none",
    "@media(max-width: 1000px)": {
        flexDirection: "column"
    }
}))

const TextWrapper = styled.div({
    flexBasis: "40%",
    "@media(max-width: 1000px)": {
        marginLeft: "2em",
        marginRight: "2em",
    }
})

const Image = styled(GatsbyImage)({
    flexBasis: "40%",
    border: "1px solid #333",
    boxShadow: "0 0 4px #000",
    borderRadius: "0.4em",
    transition: "flex-basis 300ms",
    "&:hover": {
        flexBasis: "45%"
    },
    "@media(max-width: 1000px)": {
        marginLeft: "2em",
        marginRight: "2em",
    }
})

const Content = styled.p({
    textAlign: "justify",
    fontWeight: "400",
    lineHeight: "1.35em"
})

const Feature = styled.li({
    fontSize: "1.2em",
    lineHeight: "1.35em",
    listStyle: "none",
    ...ArrowStyles("#000")
})

export default function ProductSample({ title, image, content, features, inverted }) {

    const imageData = getImage(image)
    const [ref, animate] = useOnVisibleOnce()

    const featureList = features.map((feature, i) => 
        <Feature key={i}>{feature}</Feature>
    )

    return (
        <Wrapper ref={ref} animate={animate} inverted={inverted}>
            <TextWrapper>
                <Title>{title}</Title>
                <Content>{content}</Content>
                <ul>
                    {featureList}
                </ul>
            </TextWrapper>
            <Image image={imageData} alt={title} />
        </Wrapper>
    )
}
