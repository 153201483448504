import React from "react"
import MainContainer from "../components/MainContainer"
import { graphql } from "gatsby"
import PageHeader from "../components/PageHeader"
import ProductSample from "../components/ProductSample"
import SEO from "../components/SEO"

export default function ProductDetails({ data }) {

    const { productType } = data
    const { title, description, products, tag } = productType
    const seoTitle = `${title} | Mayresur`
    const pagename = `products/${tag}`

    const productList = products.map(({ title, image, content, features }, i) => 
        <ProductSample key={i} title={title} image={image} content={content} features={features} inverted={i % 2 == 0} />
    )

    return (
        <MainContainer>
            <SEO title={seoTitle} description={description} pagename={pagename} />
            <PageHeader title={title} paragraphs={[description]}/>
            {productList}
        </MainContainer>
    )
}

export const query = graphql`
    query($tag: String!) {
        productType: productsJson(tag: {eq: $tag}) {
            title
            description
            tag
            products {
			    title
                image {
				    childImageSharp {
					    gatsbyImageData(width: 700, height: 600)
                    }
                }
                content
                features
  	        }
        }
    }
`
